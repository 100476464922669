/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import IllnessJPG from '@assets/images/illness.jpg';
import IllnessMobileJPG from '@assets/images/illness-mobile.jpg';
import { ContainerSmall } from '~/utils/styles/utils';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';
import { useTranslationContext } from '~/pages/multiple-sklerose-therapie-muenchen';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/multiple-sclerosis-therapy-munich';

const MSPage: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();

  return (
    <>
      <HeaderFooterLayout
        desktopHero={IllnessJPG}
        mobileHero={IllnessMobileJPG}
        altHero="Modell eines Gehirns zur Krankheitserklärung von Multipler Sklerose."
        enLink="/en/multiple-sclerosis-therapy-munich/"
        deLink="/multiple-sklerose-therapie-muenchen/"
      >
        <ContainerSmall>
          <div
            dangerouslySetInnerHTML={{
              __html: t('MsContent1') || tEn('MsContent1'),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('MsContent2') || tEn('MsContent2'),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('MsContent3') || tEn('MsContent3'),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('MsContent4') || tEn('MsContent4'),
            }}
          />
        </ContainerSmall>
      </HeaderFooterLayout>
    </>
  );
};

export default MSPage;
